/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.min.css";
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'focobold';
  src: url('./assets/fonts/foco-bold-webfont.eot');
  src: url('./assets/fonts/foco-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco-bold-webfont.woff') format('woff'),
       url('./assets/fonts/foco-bold-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco-bold-webfont.svg#focobold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'focoregular';
  src: url('./assets/fonts/foco-regular-webfont.eot');
  src: url('./assets/fonts/foco-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco-regular-webfont.woff') format('woff'),
       url('./assets/fonts/foco-regular-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco-regular-webfont.svg#focoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'focobold2';
  src: url('./assets/fonts/foco_bd-webfont.eot');
  src: url('./assets/fonts/foco_bd-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_bd-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_bd-webfont.woff') format('woff'),
       url('./assets/fonts/foco_bd-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_bd-webfont.svg#focobold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'focobold_italic';
  src: url('./assets/fonts/foco_bdit-webfont.eot');
  src: url('./assets/fonts/foco_bdit-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_bdit-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_bdit-webfont.woff') format('woff'),
       url('./assets/fonts/foco_bdit-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_bdit-webfont.svg#focobold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'foco_blackregular';
  src: url('./assets/fonts/foco_blk-webfont.eot');
  src: url('./assets/fonts/foco_blk-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_blk-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_blk-webfont.woff') format('woff'),
       url('./assets/fonts/foco_blk-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_blk-webfont.svg#foco_blackregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'foco_blackitalic';
  src: url('./assets/fonts/foco_blkit-webfont.eot');
  src: url('./assets/fonts/foco_blkit-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_blkit-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_blkit-webfont.woff') format('woff'),
       url('./assets/fonts/foco_blkit-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_blkit-webfont.svg#foco_blackitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'focoitalic';
  src: url('./assets/fonts/foco_it-webfont.eot');
  src: url('./assets/fonts/foco_it-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_it-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_it-webfont.woff') format('woff'),
       url('./assets/fonts/foco_it-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_it-webfont.svg#focoitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'foco_lightregular';
  src: url('./assets/fonts/foco_lt-webfont.eot');
  src: url('./assets/fonts/foco_lt-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_lt-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_lt-webfont.woff') format('woff'),
       url('./assets/fonts/foco_lt-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_lt-webfont.svg#foco_lightregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'foco_lightitalic';
  src: url('./assets/fonts/foco_ltit-webfont.eot');
  src: url('./assets/fonts/foco_ltit-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_ltit-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_ltit-webfont.woff') format('woff'),
       url('./assets/fonts/foco_ltit-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_ltit-webfont.svg#foco_lightitalic') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'focoregular';
  src: url('./assets/fonts/foco_rg-webfont.eot');
  src: url('./assets/fonts/foco_rg-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/foco_rg-webfont.woff2') format('woff2'),
       url('./assets/fonts/foco_rg-webfont.woff') format('woff'),
       url('./assets/fonts/foco_rg-webfont.ttf') format('truetype'),
       url('./assets/fonts/foco_rg-webfont.svg#focoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

html, body {
  font-family: 'focoregular';
  font-size: 14px;

  background-color: white;
}

main {
  background: linear-gradient(to bottom, #FFFF 0%,#FFF 30%,#FFF 30%,white 30%,white 100%);
}

b, strong {
  font-family: 'focobold';
}

ngx-intl-tel-input .intl-tel-input {
  display: block;
}

@import './assets/scss/amcham-box.scss';

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.w-205{width: 205%;}

.btn:has(.spinner-border){
  padding: 0 !important;
}

span:has(.spinner-border){
  display: flex;
  justify-content: center;
  align-items: center;
}